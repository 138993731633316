exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-demo-video-js": () => import("./../../../src/pages/demo-video.js" /* webpackChunkName: "component---src-pages-demo-video-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-professional-services-js": () => import("./../../../src/pages/professional-services.js" /* webpackChunkName: "component---src-pages-professional-services-js" */),
  "component---src-pages-roi-calculator-js": () => import("./../../../src/pages/roi-calculator.js" /* webpackChunkName: "component---src-pages-roi-calculator-js" */),
  "component---src-pages-routing-engine-js": () => import("./../../../src/pages/routing-engine.js" /* webpackChunkName: "component---src-pages-routing-engine-js" */),
  "component---src-pages-sms-pricing-js": () => import("./../../../src/pages/sms-pricing.js" /* webpackChunkName: "component---src-pages-sms-pricing-js" */),
  "component---src-pages-why-route-manager-js": () => import("./../../../src/pages/why-route-manager.js" /* webpackChunkName: "component---src-pages-why-route-manager-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/Industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-persona-js": () => import("./../../../src/templates/Persona.js" /* webpackChunkName: "component---src-templates-persona-js" */),
  "component---src-templates-product-feature-js": () => import("./../../../src/templates/ProductFeature.js" /* webpackChunkName: "component---src-templates-product-feature-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

